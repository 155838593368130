/* eslint-disable */
import 'core-js/es'
import 'babel-polyfill'
import './raf.js'
import './history.js'

// import 'react-app-polyfill/ie9'
// import 'react-app-polyfill/ie11'

if(process.browser){
  var RBody = document.querySelector('body')
  RBody.addEventListener('click', function(e){
    const elem = (e.target && e.target.nodeName.toUpperCase()) || ''
    if(elem=="BUTTON" || elem=="A"){
      e.target.blur()
    }
  })
}
